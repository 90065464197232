// MapComponent.js
import React from 'react';
// @ts-ignore
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
// Import marker images
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import Button from '@material-ui/core/Button';

// Fix marker icon issues with Webpack
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const LeafMapContainer = (props) => {
  const position = [-33.8143223,18.6748262]; // Coordinates for the map center

  const initialCenter =[
    -28.8166236,
    24.991639
  ]
  return (
    // <MapContainer center={props.position} zoom={props.zoom} style={props.containerStyleMobile}>
    <MapContainer center={initialCenter} zoom={6.3} style={props.containerStyleMobile}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {
          props.partners.map(address =>{
            if(address == null || address.lat == null || address.long == null)
            {
              console.log("Invalid Lat and Long for " + address.store)
                 return;
            }

            const partnerPosition = [address.lat,address.long];
              return <Marker position={partnerPosition} key={address.store}>
              <Popup>
                {!props.hideJoin && <strong>{address.store}<br/></strong> }
                {address.adress} <br/>
                { !props.hideJoin && <><a href={address.website} target="_blank">{address.website}</a> <br/>
                <br/>
                <Button 
                variant="contained" 
                size="small"
                color="secondary" 
                href={`https://portal.narfo.co.za/online-member-application?partnerId=${address.storeId}`} 
                target="_blank"
              >
                Join
              </Button></>}
              </Popup>
            </Marker>
        } )
      }
    </MapContainer>
  );
};

export default LeafMapContainer;
