import axios from 'axios';
import JWT from 'jwt-decode';

import API_URL from '../../../services/apiUrl';
import * as actionTypes from './actionTypes';

export const authStart = () => ({
  type: actionTypes.AUTH_START
});

export const authSuccess = (authToken, userId) => ({
  type: actionTypes.AUTH_SUCCESS,
  token: authToken,
  memNo: userId
});

export const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error: error
});

export const logout = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('tokenKey');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('memNo');
//  updateIsLoggedIn();
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const updateIsLoggedIn = dispatch => {
  dispatch({
	type: actionTypes.UPDATE_USER_DETAILS,
	varValue: false,
	varName: "isLoggedIn"
  })
}

export const checkAuthTimeout = (expirationTime) => dispatch => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime);
};

export const auth = (email, password) => dispatch => {

  dispatch(authStart());
  const authData = {
    email: email,
    password: password,
    username: email
  };
  axios.post(`${API_URL}/Member/post/login`, authData)
    .then(response => {

      const decodedToken = JWT(response.data.token);
      // convert unix timestamp to an actual datestring, needs milliseconds not seconds
      const tokenExpirationDate = new Date(decodedToken.exp * 1000);
      // convert the datestring to a number of seconds until token expires
      const tokenExpiryTime = tokenExpirationDate - new Date();

      localStorage.setItem('token', response.data.token);
	  sessionStorage.setItem("tokenKey", response.data.token);
	  sessionStorage.setItem("reload", "true");
      localStorage.setItem('expirationDate', tokenExpirationDate);
      localStorage.setItem('memNo', response.data.memNo);
      localStorage.setItem('store', response.data.storeId);
      console.log("Stttt",response.data)
      dispatch(authSuccess(response.data.token, response.data.memNo));
      dispatch(checkAuthTimeout(tokenExpiryTime));
	  dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: true,
        varName: "isLoggedIn"
      });
	  dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: decodedToken.nameid === "True",
        varName: 'adminLevel',
      })

      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: response.data.roles.isPartner,
        varName: 'partnerLevel',
      })

      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: response.data.roles.isSalesRep,
        varName: 'salesRepLevel'
      })

      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: response.data.roles.isPartner ? response.data.roles.partnerId : -1,
        varName: 'partnerId'
      })

    })
    .catch(err => {
      dispatch(authFail(err.response));
    });
};

export const updateRole = (isSalesRep,isPartner,partnerId) => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_USER_DETAILS,
    varValue: isPartner,
    varName: 'partnerLevel',
  })

  dispatch({
    type: actionTypes.UPDATE_USER_DETAILS,
    varValue: isSalesRep,
    varName: 'salesRepLevel'
  })

  dispatch({
    type: actionTypes.UPDATE_USER_DETAILS,
    varValue: isPartner ? partnerId : -1,
    varName: 'partnerId'
  })
};