import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/Auth/Login/actionTypes';

import * as actions from '../../../store/Auth/Login/actions';

class Logout extends Component {
  componentDidMount () {
    this.props.onLogout();
	  this.props.onUpdateIsloggedIn();
    localStorage.clear();
    sessionStorage.clear();
  }
  render () {
    
    return <Redirect to="/"/>;
    
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(actions.logout()),
  onUpdateIsloggedIn: () => dispatch({
	type: actionTypes.UPDATE_USER_DETAILS,
	varValue: false,
	varName: "isLoggedIn"
  })
});

export default connect(null, mapDispatchToProps)(Logout);