import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE } from "../../../API/APIendpoint";
import GenericHeader from "../../../utils/GenericHeader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, InputGroup } from "react-bootstrap";
import DateRange from "@material-ui/icons/DateRange";
import * as actionTypes from "../../../store/actions/actionTypes";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import MemberService from "../../../v3/services/member.service";
toast.configure();
class MembershipFee extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(this.props.token);
    this.state = {
      PaymentMethod: "",
      AmountPaid: "",
      CostCode: "",
      TransactionNumber: "",
      PaymentDate: "",
      Partners: [],
      PartnerId: 0,
      isPartnerPayment: false,
      membershipTypes: [],
      membershipTypesMonthly:[],
      selectedMembershipType: null,
      totalDue: 0,
      includeNarfoMembership: false,
      defaultMembership: 0,
      membershipLength: 365,
      isYearly: true,
      billingCyle:1
    };
    this._submitForm = React.createRef();
  }
  _clickSubmitButton = (e) => {
    e.preventDefault();
    this._submitForm.current.click();
  };

  sortStores = (stores) => {
    const sortedStores = [...stores].sort((a, b) =>
      a.store.localeCompare(b.store)
    );
    return sortedStores;
  };

  getMembershipTypes = (storeId) => {
    this.setState({
      PartnerId: storeId,
    });

    this.memberService
      .getMembershipTypes(storeId)
      .then((response) => {
        //Setup monthly payments
        var monthlyRates =  response.data.map(rates => {
          return {
              ...rates,
              fee: (rates.fee / 12).toFixed(2)
          };
      });

        this.setState({ membershipTypes: response.data ,membershipTypesMonthly: monthlyRates});

        return response.data;
      })
      .catch((error) => {
        console.log("Error retrieving Membership types ", error);
        this.setState({ membershipTypes: [],membershipTypesMonthly: [] });
      });
  };

  componentDidMount() {
    //Partners
    Axios({
      url: `${BASE}api/Stores`,
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
      },
    }).then((res) => {
      const stores = res.data || [];

      this.setState({
        Partners: this.sortStores(stores),
      });
    });

    //Get Default fee for the member
    Axios({
      url: `${BASE}api/account/GetProrataFeeByPartnerId?partnerId=${this.props.MemberV.storeId}&memberNo=${this.props.MemberV.memNo}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
      },
    })
      .then((res) => {
        this.setState({
          defaultMembership: res.data.fee,
          totalDue: this.state.membershipTypes ? 0 : res.data.fee,
          membershipLength: res.data.days
        });
      })
      .catch((err) => {
        console.log("Error retrieving pro rata fee", err);
      });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    let yearInt = parseInt(new Date().getFullYear(), 10) + 1;
    const accountInfo = {
      PaymentType: "Membership Fees " + yearInt,
      PaymentMethod: this.state.PaymentMethod,
      AmountPaid: (this.state.membershipTypes !== [] && this.state.membershipTypes?.length >  0) ? this.state.totalDue : this.state.AmountPaid,
      Email: this.props.MemberV.email,
      CostCode: this.state.CostCode,
      Paid: true,
      MemNo: this.props.MemberV.memNo,
      TransactionNo: this.state.TransactionNumber,
      PaymentDate: this.state.PaymentDate,
      StoreId: this.state.PartnerId,
    };

    if(this.state.membershipTypes !== [] && this.state.membershipTypes?.length >  0)
    {
      const membershipTypeId = this.state.selectedMembershipType?.id || "0";

      Axios({
        url: `${BASE}api/Member/ApproveNewMemberManual/${this.props.MemberV.memNo}/1/${membershipTypeId}/${this.state.billingCyle}/${this.state.includeNarfoMembership}`,
        data: accountInfo,
        method: "post",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
        },
      }).then((res) => {
        this.props.onMemViewAcctDetGet(res.data.memberAccountDetails);
        toast.success(res.data.status);
      });

    }
    else{
      Axios({
        url: `${BASE}api/Member/ApproveNewMember/${this.props.MemberV.memNo}/1`,
        data: accountInfo,
        method: "post",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
        },
      }).then((res) => {
        this.props.onMemViewAcctDetGet(res.data.memberAccountDetails);
        toast.success(res.data.status);
      });
    }
      

    this.props.onCurrAccountViewTabChange(0);
  };

  handleRadioMembershipType = (value) => {
    const total = this.state.selectedMembershipType
      ? this.state.totalDue + value.fee - this.state.selectedMembershipType.fee
      : this.state.totalDue + value.fee;
    this.setState({ selectedMembershipType: value, totalDue: total , billingCyle: 1});
  };


  handleRadioMembershipTypeMonthly = (value) => {
    // const total = this.state.selectedMembershipType ? this.state.totalDue + value.fee - this.state.selectedMembershipType.fee: this.state.totalDue + value.fee
    this.setState({ selectedMembershipType: value, totalDue: value.fee,billingCyle: 12});
  };


  handleIncludeNarfoMembership =(event) =>{
    event.preventDefault()

    if(this.state.includeNarfoMembership)
      this.setState({totalDue: this.state.totalDue - this.state.defaultMembership, includeNarfoMembership: !this.state.includeNarfoMembership})
    else
      this.setState({totalDue: this.state.totalDue + this.state.defaultMembership, includeNarfoMembership: !this.state.includeNarfoMembership})
  }
  render() {
    const info = (row1, row2) => (
      <tbody>
        <tr>
          <td style={{ width: "29%" }}>{row1}</td>
          <td style={{ width: "48%" }} />
          <td className="font-weight-bold" style={{ width: "40%" }}>
            {row2}
          </td>
        </tr>
      </tbody>
    );
    return (
      <div className="container">
        <form>
          <div>
            <GenericHeader
              title="Process Member Payment"
              showBackButton
              backFunction={() => this.props.onCurrAccountViewTabChange(0)}
            />
          </div>
          <table className="ml-4">
            {info(
              "Is Partner payment",
              <Checkbox
                style={{ marginLeft: "-15px" }}
                checked={this.state.isPartnerPayment}
                onClick={(_) =>
                  this.setState({
                    isPartnerPayment: !this.state.isPartnerPayment,
                  })
                }
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            )}

            {this.state.isPartnerPayment &&
              info(
                "Select Partner",
                <select
                  className="form-control input-sm m-0"
                  aria-describedby="emailHelp"

                  onInput={(a) => {
                    this.getMembershipTypes(a.target.value);
                  }}
                >
                  <option value="Select Partner" selected>
                    Select Partner
                  </option>
                  {this.state.Partners.map((item) => (
                    <option value={item.storeId}>{`${item.store}`}</option>
                  ))}
                </select>
              )}

            {this.state.membershipTypes?.length > 0 && (
              <div style={{ marginLeft: "20px" }}>
                <h6>
                  <strong>Select Membership Type</strong>{" "}
                </h6>

                {/* Start Monthly payments */}
                <RadioGroup row aria-label="position" name="position" defaultValue="end" >
              
              <FormControlLabel
              key={'yearly'}
              value={'yearly'}
              labelPlacement="end"
              control={
                <Radio
                 checked={this.state.isYearly}
                  color="primary"
                  //onClick={() => this.handleRadioMembershipType(membership)}
                  onClick={() => this.setState({isYearly:true, totalDue: 0,selectedMembershipType:null,includeNarfoMembership:false})}
                />}
            label={<strong>Yearly</strong>}
              />
              
              <FormControlLabel
              key={'monthly'}
              value={'monthly'}
              labelPlacement="end"
              control={
                <Radio
                  color="primary"
                  //onClick={() => this.handleRadioMembershipType(membership)}
                  onClick={() => this.setState({isYearly:false,totalDue: 0, selectedMembershipType:null,includeNarfoMembership:false})}
                />}
               label={<strong>Monthly</strong>}
              />
              
                </RadioGroup>
  
                {
                  !this.state.isYearly ? 
                  <RadioGroup row aria-label="position" name="position" defaultValue="end" >
  
                  {this.state.membershipTypesMonthly.map((membership) => (
                  <FormControlLabel
                    key={membership.id}
                    value={membership.id.toString()}
                    labelPlacement="end"
                    control={
                      <Radio
                        color="primary"
                        onClick={() => this.handleRadioMembershipTypeMonthly(membership)}
                      />
                    }
                    label={this.state.membershipTypesMonthly.length > 1 ? `R${membership.fee}/month - ${membership.name}` : `R${membership.fee}`}
                  />
    
                  ))}
                    </RadioGroup>
                  :
                  <>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="end"
                >
                  {this.state.membershipTypes.map((membership) => (
                    <FormControlLabel
                      key={membership.id}
                      value={membership.id.toString()}
                      labelPlacement="end"
                      control={
                        <Radio
                          color="primary"
                          onClick={() =>
                            this.handleRadioMembershipType(membership)
                          }
                        />
                      }
                      label={
                        this.state.membershipTypes.length > 1
                          ? `R${membership.fee}/year - ${membership.name}`
                          : `R${membership.fee}`
                      }
                    />
                  ))}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.includeNarfoMembership}
                        color="primary"
                        onClick={(event) =>
                          this.handleIncludeNarfoMembership(event)
                        }
                      />
                    }
                    label={`Include Narfo Membership(R${this.state.defaultMembership}/${this.state.membershipLength == 365
                        ? "year"
                        : this.state.membershipLength + " days"
                    })`}
                    labelPlacement="end"
                  />
                </RadioGroup>
  
                  {/* {this.props.membershipTypes?.length > 0 &&  <FormControlLabel control={
                  <Checkbox disabled={this.state.selectedMembershipType || (this.props.memberData.partnerExpiryDate ) ? false : true} checked={this.state.includeNarfoMembership} color="primary" onClick={(event) => this.handleIncludeNarfoMembership(event)} />
                  } 
                  
                  label={`Include Narfo Membership(R${this.state.defaultMembership?.amount}/${this.state.membershipLength == 365 ? 'year' : this.state.membershipLength + ' days'})`} labelPlacement="end"/>} */}
  
                  </>
                }

                {/* End Monthly payment s */}

              </div>
            )}

            {info(
              "Payment Method",
              <input
                type="text"
                className="form-control"
                onInput={(a) => {
                  this.setState({
                    PaymentMethod: a.target.value,
                  });
                }}
              />
            )}
            {
            
            this.state.membershipTypes.length <= 0? 
            // this.state.membershipTypes.length <= 0 || !this.state.isYearly ? 
            info(
              "Amount Paid " ,
                <input
                  type="text"
                  className="form-control"
                  // defaultValue={!this.state.isYearly ? this.state.totalDue : ''}
                  onInput={(a) => {
                    this.setState({
                      AmountPaid: a.target.value,
                    });
                  }}
                />
            )
            : 
            info(
              "Amount Paid",
              <input
                type="text"
                value = {Number(this.state.totalDue).toFixed(2)}
                className="form-control"
                onInput={(a) => {
                  this.setState({
                    AmountPaid: a.target.value,
                  });
                }}
              />
            )}

            {info(
              "Cost Code",
              <input
                type="text"
                className="form-control"
                onInput={(a) => {
                  this.setState({
                    CostCode: a.target.value,
                  });
                }}
              />
            )}
            {info(
              "Transaction Number",
              <input
                type="text"
                className="form-control"
                onInput={(a) => {
                  this.setState({
                    TransactionNumber: a.target.value,
                  });
                }}
              />
            )}
            {info(
              "PaymentDate",
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <DateRange />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="input"
                  type="date"
                  required
                  onInput={(a) => {
                    this.setState({
                      PaymentDate: a.target.value,
                    });
                  }}
                />
              </InputGroup>
            )}
            <button
              style={{ display: "none" }}
              onClick={(e) => this.handleSubmit(e)}
              ref={this._submitForm}
            />
          </table>
        </form>
        <div className="container">
          <div className="container placeAtEnd mr-4">
            <button
              className="btn btn-warning mt-3"
              onClick={(e) => this._clickSubmitButton(e)}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    MemberV: state.MemberViewReducer.MemberView,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onMemViewAcctDetGet: (acctDet) =>
      dispatch({
        type: actionTypes.MEM_VIEW_ACCT_DET,
        memViewAccountDet: acctDet,
      }),
    onCurrAccountViewTabChange: (newCurrAccountViewTab) =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ACCOUNT_VIEW_TAB,
        currAccountViewTab: newCurrAccountViewTab,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MembershipFee);
