import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { BASE } from '../../../API/APIendpoint';
import Grid from '@material-ui/core/Grid';
import { isMobile } from "react-device-detect";
import MapsContainer from "../MapsContainer/MapsContainer"
import LeafMapContainer from '../MapsContainer/LeafMapContainer';

const containerStyleMobile = {
    // position: 'relative',  
    // width: '740px',
    // height: '300px',
    width: '100%',
    height: '100%',
    // borderRadius:'10px',
  }

function ShootingRangeLocations() {
    const [shootingRanges, setShootingRanges] = useState([]);
    useEffect(() => {
        axios({
            url: `${BASE}api/ShootingRanges`,
            method: 'get'
        }).then(res => {
            setShootingRanges(res.data)
        })
    }, [])
    return (

        <React.Fragment>
        <Grid style={{height:"800px"}}>
            
            {/* <MapsContainer partners = {shootingRanges} zoom = {isMobile ? 5.0 : null}/> */}

            <LeafMapContainer hideJoin={true}  partners = {shootingRanges} zoom = {isMobile ? 5.0 : null} containerStyleMobile={containerStyleMobile}/>
        </Grid>
        </React.Fragment>
    );
}
export default connect(
    null,
    null
)(withRouter(ShootingRangeLocations));