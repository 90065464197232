import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { ArrowBackIos } from '@material-ui/icons/';
import {
  Button,
  Grid,
  Typography
} from '@material-ui/core/';

import API_URL from '../../services/apiUrl';
import SITE_URL from '../../services/siteURL';
import GenericTable from '../GenericTable/GenericTable';


const styles = () => ({
  heading: {
    marginBottom:'16px'
  },
});

const formatAmount = item =>
  <span> R {item}</span>;

const columns = [
  { field: 'service', headerName: 'Service' },
  { field: 'description', headerName: 'Description'},
  { field: 'amount', headerName: 'Amount', format: formatAmount},
];

class PayfastBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemName: '',
      merchantID: '',
      merchantKey: '',
      amount: 0,
      toMyAccount: false,
      dualPayment: false,
      membershipTypeId: this.props.membershipType ? this.props.membershipType.id  : null,
      includesNarfoMembership: this.props.includeNarfoMembership == true ? 'true' : 'false',
      billingCycle: this.props.billingCycle,
    };
  }

  componentDidMount() {
    const Cryptr = require('cryptr');
    const cryptr = new Cryptr('myTotalySecretKey');

    axios.get(API_URL + '/PayFastDetails').then(res => {

      let storeId = this.props.memberData.storeId;
      const membershipType = this.props.membershipType;

      if(storeId > 0)
      {
        let payfastD = res.data.find(s => s.store?.storeId == storeId);

        if(this.props.membershipType){
          this.setState({
            itemName: payfastD.itemName,
            amount: Number(this.props.totalDue).toFixed(2),
            merchantID: cryptr.decrypt(payfastD.merchantId),
            merchantKey: cryptr.decrypt(payfastD.merchantKey) 
          });

          return;
        }
      else if(payfastD != undefined || payfastD != null)
        {
          this.setState({
            itemName: payfastD.itemName,
            amount: this.props.totalDue ? this.props.totalDue : payfastD.amount,
            merchantID: cryptr.decrypt(payfastD.merchantId),
            merchantKey: cryptr.decrypt(payfastD.merchantKey) 
          });
        }
        else
        {
          //Member's partner has no amount in payfast table 
          this.setState({
            itemName: res.data[0].itemName,
            amount: res.data[0].amount,
            merchantID: cryptr.decrypt(res.data[0].merchantId),
            merchantKey: cryptr.decrypt(res.data[0].merchantKey) 
          });
        }
      }
      else{
        //Default Narfo details
        this.setState({
          itemName: res.data[0].itemName,
          amount: res.data[0].amount,
          merchantID: cryptr.decrypt(res.data[0].merchantId),
          merchantKey: cryptr.decrypt(res.data[0].merchantKey) 
        });
      }
    });
  }

  render() {
    const {classes} = this.props; 
    const _itemName = `${this.props.memberData.firstname} ${this.props.memberData.surname} ${new Date().getFullYear() + 1} Membership Fee`;
    const rows = [
      {service: 'Membership' + (this.props.billingCycle == 1 || !this.props.billingCycle ? ' - 12 Months' :" - 1 Month"),
        // description: 'Narfo 12-month membership subscription.',
        description: this.state.itemName,
        amount: this.state.amount}
    ];
    return (
      <React.Fragment>
        <Typography
          variant='h5'
          color="primary"
          className={classes.heading}
        >
          <Grid container direction="row" alignItems="center">
            <ArrowBackIos style={{"cursor": "pointer"}} onClick={() => this.props.back()}/>
            Payfast Payment
          </Grid>
        </Typography>

        <GenericTable
          className="my-7"
          rows={rows}
          columns={columns}/>
        
        <div className="row pt-2">
          <form 
            action={this.state.itemName === 'PayFast Sandbox' ? 
              'https://sandbox.payfast.co.za/eng/process' :
              'https://www.payfast.co.za/eng/process'} 
            method="POST"
          >
            <input
              type="hidden"
              name="merchant_id"
              value={this.state.merchantID.toString()}
            />
            <input
              type="hidden"
              name="merchant_key"
              value={this.state.merchantKey.toString()}
            />
            <input type="hidden" name="return_url" value={`${SITE_URL}return`} />
            <input type="hidden" name="cancel_url" value={`${SITE_URL}cancel`} />
            <input type="hidden" name="notify_url" value={`${API_URL}/account/memPayfast`} />
            <input
              type="hidden"
              name="name_first"
              value={this.props.memberData.username}
            />
            <input
              type="hidden"
              name="email_address"
              value={this.props.memberData.email}
            />
            <input
              type="hidden"
              name="custom_str2"
              value={this.props.memNo}
            />

            <input
              type="hidden"
              name="custom_str3"
              value={this.state.membershipTypeId ? this.state.membershipTypeId : ""}
            />
            <input
              type="hidden"
              name="custom_str4"
              value={this.state.includesNarfoMembership} //Includes narfo membership fee
            />

            <input
              type="hidden"
              name="custom_str5"
              value={this.state.billingCycle ? this.state.billingCycle : ""} // Billing type
            />

            <input type="hidden" name="m_payment_id" value="01AB" />
            <input type="hidden" name="amount" value={this.state.amount} />
            <input 
              type="hidden" 
              name="item_name" 
              value= {_itemName}
            />
            <input
              type="hidden"
              name="item_description"
              value="Narfo Membership"
            />
            <input type="hidden" name="custom_int1" value="2" />

            <input type="hidden" name="email_confirmation" value="1" />
            <input
              type="hidden"
              name="confirmation_address"
              value="info@narfo.co.za" // We'll need to customize this for different partners
            />
            <Button
              type="submit"
              size="small"
              disableElevation
              variant="contained"
              color="primary" 
              onMouseDown={() => {}}
            >
              Proceed to Payfast
            </Button>
          </form>
        </div>

        
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  memberData: state.ProfileReducer.memberData,
});

export default connect(mapStateToProps, null)(withStyles(styles)(PayfastBlock));