import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import API_URL from '../../services/apiUrl';
import { 
  Typography,
  Grid,
  Button,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PropType from 'prop-types';
import {DateTime} from 'luxon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { 
  ManualPayment,
  PayfastBlock,
  SubtitleBar 
} from '../index';
import GenericTable from '../GenericTable/GenericTable';
import { formatISODateToLocaleString } from '../../shared/helpers';
import PayfastBlockSubscription from '../PaymentHandling/PayfastBlockSubscription';
import PayfastRecurringBlock from '../PaymentHandling/PayfastRecurringBlock';

const formatAmount = item =>
  <span> R {item}</span>;

const columns = [
  { field: 'paymentType', headerName: 'Payment Type' },
  { field: 'amountPaid', 
    headerName: 'Amount Paid',
    format: formatAmount,},
  {
    field: 'paymentDate',
    headerName: 'Payment Date',
    description: 'Click on the arrow to change sort order',
    sortable: true,
    format: (item) => formatISODateToLocaleString(item), 
    sortFunction: (itemData, sortOrder) => itemData
      .sort((b, a) => sortOrder * new Date(a.paymentDate) - sortOrder * new Date(b.paymentDate)),  
  },
];

const columnsMobile = [
	{ field: 'amountPaid', 
	  headerName: 'Paid',
	  format: formatAmount,},
	{
	  field: 'paymentDate',
	  headerName: 'Date',
	  description: 'Click on the arrow to change sort order',
	  sortable: true,
	  format: (item) => formatISODateToLocaleString(item), 
	  sortFunction: (itemData, sortOrder) => itemData
		.sort((b, a) => sortOrder * new Date(a.paymentDate) - sortOrder * new Date(b.paymentDate)),  
	},
  ];

const CURRENT_PASSWORD = 'currentPassword';
const NEW_PASSWORD = 'newPassword';
const CONFIRM_PASSWORD = 'confirmPassword';

const styles = (theme) => ({
  paragraph: {
    ...theme.typography.paragraph,
    fontSize: '1rem',
    paddingLeft: '0',
    textAlign: 'justify',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  onlineButton: {
    margin: theme.spacing(4),
  },
  offlineButton: {
    margin: theme.spacing(4),
  },
});

class ProfileAccountDetails extends Component {

  constructor(props) {
    super(props);

    //Get member number from local storage
    let memberNumber = localStorage.getItem('memNo');

    this.state = {
      data: {
        currentPassword: '',
        memberNumber:  memberNumber ? memberNumber : 'NM-001',
        newPassword: '',
        confirmPassword: '',
      },
      passwordErrors: {
        old: '',
        new: '',
        confirm: '',
      },
      viewAccountDetails: false,
      account: {},
      payFastView: false,
      payFastMonthlyView : false,
      manualPayView: false,
      isPasswordChangeSuccess :false,
      selectedMembershipType: null,
      defaultMembership: null,
      includeNarfoMembership:false,
      totalDue: 0,
      payFastRecurringView : false,
      isYearly: true,
      membershipLength:0,
      ...this.setupPaymentDetails()
    };
    this.handleManualPayView = this.handleManualPayView.bind(this);

  }

  componentDidMount(){
    this.setupDefaultPayment();

        //Get history of accounts 
        // axios.get(API_URL + "/account/member/" + this.props.memberData.memNo, {
        //   headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
        // }).then(res => {
        //     if(res.data.length > this.props.accountHistory.length){
        //       this.setState({accountHistory: res.data})
        //     }
        //   }
    
        // );
  }
  setupPaymentDetails(){
    const membership = this.props.membershipTypes.filter(m => m.cycle != 'M');  // Yearly fees

    var monthlyRates =  this.props.membershipTypes.filter(m => m.cycle === 'M');
    
    return({
      membershipTypes: membership,
      membershipTypesMonthly: monthlyRates
    })
  }


  setupDefaultPayment=()=>{

    let defaultNarfo = null;

    axios.get(API_URL + '/PayFastDetails').then(res => {

      let storeId = this.props.memberData.storeId;

      if(storeId > 0)
      {
        let payfastD = res.data.find(s => s.store?.storeId == storeId);

        if(payfastD)
        {
          axios.get(API_URL + `/account/GetProrataFeeByPartnerId?partnerId=${storeId}&memberNo=${this.props.memberData.memNo}`).then(res => {
  
            this.setState({membershipLength: res.data.days})
            if(payfastD != undefined || payfastD != null)
            {
                payfastD.amount = res.data.fee;
                defaultNarfo = payfastD;
  
                this.setState(
                  {
                    defaultMembership: {...defaultNarfo},
                    totalDue: this.state.membershipTypes ? 0: res.data.fee
                  })
                  
            }
            else
              defaultNarfo = res.data[0];
          })
          .catch(error => {
            console.log("Error retrieving pro rata fee", error);
            defaultNarfo = payfastD;
          });
        }
        else{
          //Assign default Narfo amount 
          defaultNarfo = res.data[0];

          this.setState(
          {
            defaultMembership: {...defaultNarfo},
            totalDue: !defaultNarfo ? 0 : defaultNarfo.amount
          })
  
        }
      }
      else
        defaultNarfo = res.data[0];

        this.setState(
        {
          defaultMembership: {...defaultNarfo},
          totalDue: !defaultNarfo ? 0 : defaultNarfo.amount
        })

    });
  }
  handleSubmit = (event) => {

    event.preventDefault();
    this.props.resetPasswordCallback(this.state.data)
      .then((response) =>{
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            currentPassword: '',
            confirmPassword: '',
            newPassword: '',
          },
          passwordErrors: {
            old: '',
            new: '',
            confirm: '',
          },
          isPasswordChangeSuccess:true})
        })
      .catch((err) => 
      {
        this.setState({
        ...this.state,
        passwordErrors: {old: err.response.data.message},
        isPasswordChangeSuccess:false
      })});
  }
  
  handleInput = event => {
    const name = event.target.name;
    const newValue = event.target.value;

    //handle errors
    if(name === NEW_PASSWORD) {
      this.setState({
        passwordErrors: {
          ...this.state.passwordErrors,
          new: newValue.length < 8 ?
            'Password length must be at least 8 characters' : '',
          confirm: '',
        },
        data: {
          ...this.state.data,
          confirmPassword: '',
          [name]: newValue,
        },
        isPasswordChangeSuccess:false
      });
    }

    if(name === CURRENT_PASSWORD) {
      this.setState({
        data: {
          ...this.state.data,
          [name]: newValue,
        },
        isPasswordChangeSuccess:false
      });
    }

    if(name === CONFIRM_PASSWORD) {
      this.setState({
        passwordErrors: {
          ...this.state.passwordErrors,
          confirm: newValue !== this.state.data.newPassword ?
            'Passwords must match' : '',
        },
        data: {
          ...this.state.data,
          [name]: newValue,
        },
        isPasswordChangeSuccess:false
      });
    }
  };
  
  handlePayFastView = () => {

    this.setState((prevState) => ({
      payFastView: !prevState.payFastView
    }));
  };

  handlePayFastSubscription = () => {
    this.setState((prevState) => ({
      payFastMonthlyView: !prevState.payFastMonthlyView
    }));
  };

  // handlePayFastRecurring = () => {
  //   console.log("State", this.state.payFastRecurringView)
  //   this.setState((prevState) => ({
  //     payFastRecurringView: !prevState.payFastRecurringView
  //   }));
  // };
  

  handleManualPayView = () => {
    if(this.state.manualPayView)
      {
        //Clear the form 
      }

    this.setState((prevState) => ({
      manualPayView: !prevState.manualPayView
    }));
  }

  onViewTableItem = (item) => {

    item.data = item.data?.map(invoice => {
      return {
        ...invoice,
        invoiceDate: new Date(invoice.invoiceDate).toLocaleDateString()
      };
    });

    this.setState({
      viewAccountDetails: true,
      account: {...item},
    });
  }

  handleRadioMembershipType = (value) => {
    const total = this.state.selectedMembershipType ? this.state.totalDue + value.fee - this.state.selectedMembershipType.fee: this.state.totalDue + value.fee
    this.setState({ selectedMembershipType: value, totalDue: total});
  };

  handleRadioMembershipTypeMonthly = (value) => {
    // const total = this.state.selectedMembershipType ? this.state.totalDue + value.fee - this.state.selectedMembershipType.fee: this.state.totalDue + value.fee
    this.setState({ selectedMembershipType: value, totalDue: value.fee});
  };


  handleIncludeNarfoMembership =(event)=>{
    event.preventDefault();

    let total = !this.state.includeNarfoMembership  
    ? this.state.totalDue + this.state.defaultMembership.amount 
    : this.state.totalDue - this.state.defaultMembership.amount

    this.setState({totalDue: total,includeNarfoMembership: !this.state.includeNarfoMembership})

    
  }

  gridItem = (title, value, isMobile) => (
    <Grid item container xs={12} sm={4} md={4} spacing={0} direction="column" alignItems="flex-start">
      <Grid item>
        <Typography variant={isMobile ? "h6" : "body2"} align="left">{value || '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography align="left">{title || '-'}</Typography>
      </Grid>
    </Grid>
  )

  accountDetails = (account = {}, isMobile) => (
    <React.Fragment>
      <SubtitleBar 
        variant="subtitle2" title="Account Details" 
        back onBack={() => this.setState({viewAccountDetails: false})}
      />

      {
      [...this.state.account.data].reverse().map(invoiceDetails =><div className="inner-shadow border-radius-2 py-5 px-4 bg-white-2 my-5">
      <div style={{marginTop:'-50px'}}>
        { invoiceDetails.costCode !== null? <SubtitleBar 
          variant="subtitle2" title="Narfo Membership" 
          printer 
          callBack={() => this.props.printAccountTransactionCallback(invoiceDetails)}
        />
          :
        <SubtitleBar 
         variant="subtitle2" title={invoiceDetails.paymentType}
        />
        }
      </div>
        <Grid container spacing={2}>
          {this.gridItem('Invoice date', invoiceDetails.invoiceDate, isMobile)}
          {this.gridItem('Invoice number', invoiceDetails.invoiceNumber, isMobile)}
          {this.gridItem('Payment method', invoiceDetails.paymentMethod, isMobile)}
          {this.gridItem('Receipt number', invoiceDetails.recieptNumber, isMobile)}
          {this.gridItem('Year', invoiceDetails.year, isMobile)}
          {this.gridItem('Billing cycle', invoiceDetails.billingCycle ?? 'Yearly', isMobile)}
          {this.gridItem('Amount', 'R '+invoiceDetails.amountPaid, isMobile)}
          <Grid item xs={12} className="py-5">
            <Divider />
          </Grid>
          {this.gridItem('Transaction number', invoiceDetails.transactionNo, isMobile)}
        </Grid>

        
      </div>)
      }

    </React.Fragment>
  );

  render() {
    const {classes} = this.props;
    const passwordErrors = this.state.passwordErrors;
    const expiryDate = DateTime.fromISO(this.props.accountStatus.expiryISO);
    const daysLeft = expiryDate.diffNow('days').days;
    const partnerExpiryDate = DateTime.fromISO(this.props.memberData.partnerExpiryDate);
    const partnerDaysLeft = Math.round(partnerExpiryDate.diffNow('days').days);
    const isMobile = localStorage.getItem('isMobile') === 'true';

    
    const paymentBlock = (
      <React.Fragment>
        <Grid item container direction="column" alignItems="center" justify="flex-start">

          <Typography className={classes.paragraph}>
              You may proceed by making an online payment or a direct deposit.
          </Typography>
          <Typography className={classes.paragraph}>
              Please follow the appropriate link below.
          </Typography>

            <br/>
          {
            this.props.membershipTypes?.length > 0 && <FormControl component="fieldset">
            <FormLabel component="legend">Select Membership Type</FormLabel>

            <RadioGroup row aria-label="position" name="position" defaultValue="end" >
              
            <FormControlLabel
            key={'yearly'}
            value={'yearly'}
            labelPlacement="end"
            control={
              <Radio
               checked={this.state.isYearly}
                color="primary"
                //onClick={() => this.handleRadioMembershipType(membership)}
                onClick={() => this.setState({isYearly:true, totalDue: 0,selectedMembershipType:null,includeNarfoMembership:false})}
              />}
          label={<strong>Yearly</strong>}
            />
            
            <FormControlLabel
            key={'monthly'}
            value={'monthly'}
            labelPlacement="end"
            control={
              <Radio
                color="primary"
                //onClick={() => this.handleRadioMembershipType(membership)}
                onClick={() => this.setState({isYearly:false,totalDue: 0, selectedMembershipType:null,includeNarfoMembership:false})}
              />}
             label={<strong>Monthly</strong>}
            />
            
              </RadioGroup>

              {
                !this.state.isYearly ? 
                <RadioGroup row aria-label="position" name="position" defaultValue="end" >

                {this.state.membershipTypesMonthly.map((membership) => (
                <FormControlLabel
                  key={membership.id}
                  value={membership.id.toString()}
                  labelPlacement="end"
                  control={
                    <Radio
                      color="primary"
                      onClick={() => this.handleRadioMembershipTypeMonthly(membership)}
                    />
                  }
                  label={this.state.membershipTypesMonthly.length > 1 ? `R${membership.fee}/month - ${membership.name}` : `R${membership.fee}`}
                />
  
                ))}
                  </RadioGroup>
                :
                <>
              <RadioGroup row aria-label="position" name="position" defaultValue="end" >

              {this.state.membershipTypes.map((membership) => (
              <FormControlLabel
                key={membership.id}
                value={membership.id.toString()}
                labelPlacement="end"
                control={
                  <Radio
                    color="primary"
                    onClick={() => this.handleRadioMembershipType(membership)}
                  />
                }
                label={this.state.membershipTypes.length > 1 ? `R${membership.fee}/year - ${membership.name}` : `R${membership.fee}`}
              />

              ))}
                </RadioGroup>

                {this.props.membershipTypes?.length > 0 &&  <FormControlLabel control={
                <Checkbox disabled={this.state.selectedMembershipType || (this.props.memberData.partnerExpiryDate ) ? false : true} checked={this.state.includeNarfoMembership} color="primary" onClick={(event) => this.handleIncludeNarfoMembership(event)} />
                } 
                
                label={`Include Narfo Membership(R${this.state.defaultMembership?.amount}/${this.state.membershipLength == 365 ? 'year' : this.state.membershipLength + ' days'})`} labelPlacement="end"/>}

                </>
              }

              <Typography color="primary" variant="h5">
                      Total Due: R{Number(this.state.totalDue).toFixed(2)}
              </Typography>
          </FormControl>
          
          }

        </Grid>
        <Grid item container direction="row" justify="center">
          
          <Grid item>
            {this.props.memberData.storeId == 1 && <Button 
              size="medium"
              variant="contained"
              color="secondary"
              disableElevation
              onClick={this.handlePayFastSubscription}
              className={classes.onlineButton}
            >
              
              {isMobile ? 'Subscription' : 'Auto renew'}
            </Button>}

            {/* Subscription for Other partners */}
            {this.props.memberData.storeId !== 1 && <Button 
            disabled={this.state.totalDue <= 0 && this.props.membershipTypes?.length > 0}
              size="medium"
              variant="contained"
              color="secondary"
              disableElevation
              onClick={this.handlePayFastSubscription}
              className={classes.onlineButton}
            >
              
              {isMobile ? 'Subscription' : 'Auto renew'}
            </Button>}
            
            <Button 
              disabled={this.state.totalDue <= 0 && this.props.membershipTypes?.length > 0}
              size="medium"
              variant="contained"
              color="secondary"
              disableElevation
              onClick={this.handlePayFastView}
              className={classes.onlineButton}
            >
              
              {isMobile ? 'PayFast' : 'Pay with PayFast'}
            </Button>
          </Grid>
          <Grid item>
            <Button 
              disabled={this.state.totalDue <= 0  && this.props.membershipTypes?.length > 0}
              size="medium"
              disableElevation
              variant="contained"
              color="secondary" 
              onClick={this.handleManualPayView}
              className={classes.offlineButton}
            >
              {isMobile ? 'Deposit' : 'Pay with Bank Deposit'}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
    
    const manualBlock = (
      <React.Fragment>
        {
          this.state.selectedMembershipType ?
        <ManualPayment totalDue={Number(this.state.totalDue).toFixed(2)} membershipType={this.state.selectedMembershipType} includeNarfoMembership={this.state.includeNarfoMembership} back={this.handleManualPayView} memberNumber={this.state.data.memberNumber} storeId={this.props}/>
        : 
        <ManualPayment totalDue={this.state.defaultMembership?.amount} back={this.handleManualPayView} memberNumber={this.state.data.memberNumber} storeId={this.props}/>

        }
        <Grid item>
          <Button
            size="small"
            disableElevation
            variant="contained"
            color="primary" 
            onClick={this.handleManualPayView}
            className={classes.offlineButton}
          >
            Return to payment options
          </Button>
        </Grid>
      </React.Fragment>
    );

    const payfastBlock = (
      <React.Fragment>
        {
          this.state.selectedMembershipType || this.state.membershipTypes.length > 0 ?
          <>           
              {/* <PayfastBlock totalDue={this.state.totalDue} billingCycle={this.state.isYearly ? 1 : 12} membershipType={this.state.selectedMembershipType} includeNarfoMembership={this.state.includeNarfoMembership} back={this.handlePayFastView}/> */}

            {/* {!this.state.totalDue <= 0 && <PayfastRecurringBlock totalDue={this.state.totalDue} membershipType={this.state.selectedMembershipType}  back={this.handlePayFastRecurring} billingCycle={this.state.isYearly ? 1 : 12}/>} */}
             <PayfastRecurringBlock totalDue={this.state.totalDue} membershipType={this.state.selectedMembershipType}  back={this.handlePayFastSubscription} billingCycle={this.state.isYearly ? 1 : 12}/>
        
          </>
          :
            <>

          {/* * Narfo Subscription                */}
        {this.state.payFastMonthlyView && <PayfastBlockSubscription  back={this.handlePayFastSubscription}/>}
        
        {this.state.payFastView && <PayfastBlock back={this.handlePayFastView}/>}
         </>
        }

      </React.Fragment>
    );

    return this.state.viewAccountDetails ? this.accountDetails(this.state.account, isMobile) :
      (
        <div className="pb-4">
          <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2 mt-5">
            <SubtitleBar variant="subtitle2" title="My Account"/>
            {this.props.accountHistory.length !== 0 && daysLeft <= 45 && daysLeft > 0 ?
              <Grid item container direction="column" alignItems="center" justify="flex-start">
                <Typography color="error" className="pb-4">
                    Your account will expire in {Math.floor(daysLeft)} days, please re-new your <strong>Narfo</strong> membership.
                </Typography>
              </Grid>
              : null
            }

            {partnerDaysLeft && this.props.accountHistory.length !== 0 && partnerDaysLeft <= 45 && partnerDaysLeft > 0 ?
              <Grid item container direction="column" alignItems="center" justify="flex-start">
                <Typography color="error" className="pb-4">
                Your <strong>{this.props.memberData.store}</strong> membership will expire in {Math.floor(partnerDaysLeft)} days, please re-new.
                </Typography>
              </Grid>
              : null
            }

            { true?
              
              this.state.manualPayView ? 
                <Grid container direction="column">
                  {manualBlock}
                </Grid> :
                this.state.payFastView || this.state.payFastMonthlyView? 
                  <Grid container direction="column">
                    {payfastBlock}
                  </Grid>
                  :
                  <Grid item container>
                    {paymentBlock}
                  </Grid> :
                  <></>
            }
            { this.props.accountHistory.length > 0 &&
              <GenericTable
              rows={this.props.accountHistory}
              columns={isMobile ? columnsMobile : columns} view
              onViewCallBack={this.onViewTableItem}/>
            }
          </div>
          <SubtitleBar title="Password" />
          <div className="inner-shadow border-radius-2 py-5 px-4 bg-white-2 my-5">
            <form onSubmit={this.handleSubmit} id="password-form">
              <Grid container direction="column">
                <Grid item container justify="space-between">
                  <Typography variant="subtitle2" align="left" className="mb-5">Reset Password</Typography>
                  <Button type="submit" variant="contained" color="primary" form="password-form">Reset</Button>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth error={passwordErrors.old !== ''}>
                      <InputLabel htmlFor="current-password">Current Password</InputLabel>
                      <Input
                        name={CURRENT_PASSWORD}
                        placeholder="Enter current password"
                        aria-describedby="current-pass-help"
                        onChange={this.handleInput}
                      />
                      { passwordErrors.old &&
                        <FormHelperText id="current-pass-help">
                          {passwordErrors.old}
                        </FormHelperText>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth error={passwordErrors.new !== ''}>
                      <InputLabel htmlFor="new-password">New Password</InputLabel>
                      <Input
                        name={NEW_PASSWORD}
                        id="new-password"
                        placeholder="enter new password"
                        aria-describedby="new-pass-help"
                        onChange={this.handleInput}
                      />
                      { passwordErrors.new &&
                        <FormHelperText id="confirm-pass-help">
                          {passwordErrors.new}
                        </FormHelperText>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth error={passwordErrors.confirm !== ''}>
                      <InputLabel htmlFor="confirm-password">Confirm New Password</InputLabel>
                      <Input
                        name={CONFIRM_PASSWORD}
                        id="confirm-password"
                        placeholder="confirm new password"
                        aria-describedby="confirm-pass-help"
                        value={this.state.data.confirmPassword}
                        onChange={this.handleInput}
                      />
                      { passwordErrors.confirm &&
                        <FormHelperText id="confirm-pass-help">
                          {passwordErrors.confirm}
                        </FormHelperText>
                      }
                    </FormControl>
                  </Grid>
                    <p hidden={!this.state.isPasswordChangeSuccess} className="col-12 text-success">Password changed successfully</p>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      );
  }
}

ProfileAccountDetails.defaultProps = {
  accountStatus: {},
  accountHistory: [],
  printAccountTransactionCallback: (account) => account,
  resetPasswordCallback: (data) => Promise.resolve(data),
};

ProfileAccountDetails.propTypes = {
  accountStatus: PropType.shape({
    status: PropType.string,
    inceptionDate: PropType.string,
    expiryDate: PropType.string,
    expiryISO: PropType.string
  }).isRequired,
  accountHistory: PropType.arrayOf(
    PropType.shape({
      paymentType: PropType.string,
      amountPaid: PropType.number,
      paymentDate: PropType.string,
    })).isRequired,
  printAccountTransactionCallback: PropType.func.isRequired,
  resetPasswordCallback: PropType.func.isRequired,
};

export default (withStyles(styles)(ProfileAccountDetails));
