import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar,
  IconLabelButton
} from '../index';
import { Grid, Typography} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import DedicatedService from '../../services/dedicatedstatus.service';
import {
  printCard,
  printCertificate,
  printGOSACertificate,
  printPCOASACertificate
} from '../../shared/pdfHelpers';
import MemberService from '../../services/member.service';
import {
  MEMBER_STATUS,
} from '../../shared/constants';
import MemberActions from '../../store/Profile/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

class MembershipReports extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(this.props.token);
    this.dedicatedService = DedicatedService(this.props.token);
    this.memberActions = this.props.memberActions(this.memberService, this.dedicatedService);
    this.state = {
      report : null,
      storeName: '',
      isDownloading: false,
    }
  }
  
  componentDidMount(){
    
    this.memberService.getPartnerReport(this.props.memberData.storeId)
    .then(reports =>{

      const report = reports.data.length > 1 ? reports.data.find(r => r.type == this.props.memberData.membershipTypeId.toString()) : reports.data?.[0];
      this.setState({report: report, storeName: report?.store?.store});

      
    })
  }
  downloadMemberCard = () => {
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.dedicatedService.getApprovedDedicatedStatus(memberId)
          .then((res) => {
            let dsHunter = res.data
              .find(ds => ds.dstype.toLowerCase().includes('hunter') && ds.status === MEMBER_STATUS.APPROVED) || {};
            let dsSports = res.data
              .find(ds => ds.dstype.toLowerCase().includes('sport') && ds.status === MEMBER_STATUS.APPROVED) || {};
            return { dsHunter, dsSports };
          }).then(({ dsHunter, dsSports }) => this.memberService.getAllImages().then((images) =>
            printCard(this.props.memberData, dsHunter.dsno, dsSports.dsno,
              images.data.narfo, images.data.wildman, base64QRCode)
          ));
      });
  }

  downloadMemberCertificate = () => {
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.memberService.getAllImages().then((images) =>
          printCertificate(
            this.props.memberData, images.data.nnfo, base64QRCode,
            images.data.pdfSign, images.data.pdflogo
          )
        );
      });
  }

  downloadMemberPartnerCertificate =()=>{
    this.setState({isDownloading:true});
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        const storeId = this.props.memberData.storeId;
        
        return this.memberService.getPartnerLogo(storeId,this.state.report.logo).then((images) =>{
            switch (this.props.memberData.store) {
              case 'GOSA':
                  printGOSACertificate(this.props.memberData, images.data.pdflogo, this.state.report.name)
                  this.setState({isDownloading:false});
                break;
              case 'PCOASA':
                  printPCOASACertificate(this.props.memberData, images.data.pdflogo, base64QRCode,images.data.pdfSignature, images.data.pdflogo)
                  this.setState({isDownloading:false});
                break;
                
              default:
                console.log("Invalid Member store Name ", this.props.memberData.store);
                alert("error downloading report")
                this.setState({isDownloading:false});
                break;
            }            
        }
        ).catch(err =>{
          alert("error downloading report")
          console.log("Error downloading Report ", err);
          this.setState({isDownloading:false});
        });
      });
  }
  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <React.Fragment>
          <div className="border-radius-2 py-2 px-4 bg-white-2 mt-4">
            <SubtitleBar
              variant="subtitle2"
              title={'Membership Reports'}
            />

        <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}><strong>Narfo</strong> Membership Card</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                <IconLabelButton
                // disabled={this.props.memberData.status !== 1 || this.props.memberData.status != 4}
                  actionText="Download"
                  icon={<CloudDownload />}
                  disabled= {!(this.props.memberData.status === 1 || this.props.memberData.status === 4)}
                  full
                  callBack={() => this.downloadMemberCard()}
                />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}><strong>Narfo</strong> Membership Certificate</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <IconLabelButton
                  // disabled={this.props.memberData.status !== 1 || this.props.memberData.status != 4}
                    actionText="Download"
                    disabled= {!(this.props.memberData.status === 1 || this.props.memberData.status === 4)}
                    icon={<CloudDownload />}
                    full
                    callBack={() => this.downloadMemberCertificate()}
                  />
                </Grid>

                {/* For different Partner  */}
                { this.state.report && (this.props.memberData.partnerExpiryDate || (this.props.memberData?.storeId == this.state.report?.storeId))&& <> <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}><strong>{this.state.storeName} </strong>{this.state.report.name}</Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <IconLabelButton
                    actionText="Download"
                    icon={this.state.isDownloading ? <CircularProgress size="1.5rem" disableShrink /> : <CloudDownload />}
                    full
                    callBack={() => this.downloadMemberPartnerCertificate()}
                  />
                  
                </Grid> </>}
            

              </Grid>

          </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  memberData: state.ProfileReducer.memberData,
});

const initialiseMemberActions = dispatch => (memberService, dedicatedStatusService) => {
  const action = MemberActions(memberService, dedicatedStatusService);
  return {
    fetchMemberData: (id) => dispatch(action.fetchMemberData(id)),
  };
};

const mapDispatchToProps = dispatch => ({
  memberActions: initialiseMemberActions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipReports);