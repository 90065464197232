import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
//Create a MapsContainer in the v3 directory
import LeafMapContainer from "../MapsContainer/LeafMapContainer"
import API_URL from '../../../v3/services/apiUrl';

import Grid from '@material-ui/core/Grid';

const containerStyleMobile = {
    // position: 'relative',  
    // width: '740px',
    // height: '300px',
    width: '100%',
    height: '100%',
    // borderRadius:'10px',
  }
  
function PartnersLocation(props) {
	const [partners, setPartners] = useState([]);
    useEffect(() => {
        axios({
            url: `${API_URL}/Stores`,
            method: 'get',
        }).then(res => {
            setPartners(res.data)
        })
    }, [])

    return (
        <React.Fragment>
            <Grid style={{height:"800px"}}>
            {/* <Grid style={containerStyleMobile}> */}

                {/* <MapsContainer partners = {partners} zoom = {isMobile ? 5.0 : null}/> */}

                <LeafMapContainer  partners = {partners} zoom = {isMobile ? 5.0 : null} containerStyleMobile={containerStyleMobile}/>
            </Grid>
        </React.Fragment>
    );
}
export default connect(
    null,
    null
)(withRouter(PartnersLocation));