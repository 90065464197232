import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as actionTypes from '../../../store/actions/actionTypes';
import {
  AppBar,
  Button,
  Collapse,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Toolbar,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  TextField
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {DateTime} from 'luxon';

import CommissionsService from "../../services/commissions.service";

import {
  Assessment,
  Assignment,
  Book,
  ChromeReaderMode,
  ExpandLess,
  ExpandMore,
  Home,
  Menu,
  MonetizationOn,
  Notifications,
  Public,
  Person,
  PowerSettingsNew,
  Stars,
  Subscriptions,
  SupervisorAccount,
  BookmarkRounded,
  Email,
  CloudDownload
} from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import logo from '../../assets/narfoLogo.jpg';
import {AuthAvatar} from '../../components';
import { Alert, AlertTitle } from '@material-ui/lab';
import EmailVerificationService from '../../services/emailVerification.service'
import axios from "axios";
// import API_URL from '../../../v3/services/apiUrl';
import API_URL from '../../../v3/services/apiUrl';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerClosed: {
    width: 0
  },
  drawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('lg')]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: '20px',
    }
  },
  sideBarListMargin: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('md')]: {
      marginBottom: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '35px',
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: '40px',
    }
  },
  drawerPaper: {
    width: drawerWidth,
  },
  backdrop: {
    background: theme.palette.common.white2,
    [theme.breakpoints.up('md')]: {
      background: theme.palette.navBarBackdrop.background,
    }
  },
  navTab: {
    ...theme.typography.nav,
    fontFamily: theme.typography.fontFamily,
    minWidth: '8px',
  },
  logoContainer: {
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  navBarLogo: {
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      height: '40px',
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      height: '50px',
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: '60px',
      width: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      height: '75px',
      width: 'auto',
    }
  },
  sideBarLogo: {
    width: '100%',
  },
  buttonText: {
    color: theme.palette.buttonPrimary,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.buttonContrast.main,
    }
  },
  listParent: {
    width: '100%',
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  listSelected: {
    borderRadius: '10px',
    '& .MuiListItemText-root': {
      opacity: 1
    }
  },
  avatarLabel: {
    fontSize: theme.typography.nav.fontSize,
    
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.contrastText,
    }
  },
  menuButton: {
    padding: '0px'
  }
}));

function NavBar(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [navIndex, setNavIndex] = useState('home');
  const [sideIndex, setSideIndex] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [webListOpen, setWebListOpen] = useState(false);
  const [memberListOpen, setMemberListOpen] = useState(false);
  const [hasSalesReps, setHasSalesReps] = useState(false);
  const [_isSalesRep, setIsSalesRep] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = React.useState(false); // Snackbar 1
  const [openVerify, setOpenVerify] = React.useState(false); // Snackbar 2
  const [openVerifyError, setOpenVerifyError] = React.useState(false);
  const [loggedIn,] = React.useState(props.isAuth);
  
  const [code, setCode] = useState(null);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const _adminLevel = props.adminLevel
  const _partnerLevel = props.partnerLevel
  const _salesRepLevel = props.salesRepLevel
  const expiryDate = DateTime.fromISO(props.memberData.expiryDate);
  const daysLeft = expiryDate.diffNow('days').days;
  const commisionService = CommissionsService(props.token);
  const emailVerificationService = EmailVerificationService(props.token);
  /**/
  const [value, setValue] = useState(0);
  const [partnerDetails, setPartnerDetails] = useState(null);
  /**/

  const breakpointMatch = useMediaQuery(theme.breakpoints.up('md'));

  const handleChangeSideIndex = (e, sideIndex) => {
	setMobileOpen(!mobileOpen);
    setSideIndex(sideIndex);
    setNavIndex(false);
  };

  const handleChangeNavIndex = (e, navIndex) => {
    setMobileOpen(!mobileOpen);
    setNavIndex(navIndex);
    setSideIndex(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMemberToggle = () => {
    setMemberListOpen(!memberListOpen);
  };
  
  /**/
  const handleChange = (event, value) => {
	setValue(value);
	toOverviewPageDefaulter(value);
	props.onLandingPageContentToggle(value);
	if(window.location.pathname.split('/')[1] !== 'LandingPage') {
		window.location = '/LandingPage';
	}
  };

  const toOverviewPageDefaulter = (value) => {
	if (value === 1) {
		props.onFirearmOwnersToggle(0)
	} else if (value === 2) {
		props.onHuntingToggle(0)
	} else if (value === 3) {
		props.onSportShootingToggle(0)
	}
  }

  useEffect(() => {
    const status =  window.localStorage.getItem("verified");

    if(props.memberData.status == 9){
      expiredProfileRoutes.push({ name: 'License Portal', link: '/member/license-reminders', value: 'license-reminders', icon: <Notifications/> });
    }

    if(status === 'true')
    {
      openSnackbarVerify();
      window.localStorage.setItem("verified", false);
    }
    let __isSalesRep = false;
    let __hasSalesReps = false;

    if(props.salesReps.length !== 0){
      __hasSalesReps = true;
      props.salesReps.map(item => {
        if(item.memNo === props.memNo)
          commisionService.getAllSalesCommissions(props.memNo).then(response =>{
            if(response.data?.repsMemComm.length > 0)
            {
              __isSalesRep = true;
              setIsSalesRep(true);
            }
            else{
              __isSalesRep = false;
              setIsSalesRep(__isSalesRep);
            }
        });
      })
    }
    else
    {
      setIsSalesRep(__isSalesRep);
    }

    setHasSalesReps(__hasSalesReps);
    
  });

  {/* Navbar tabs */}
  // const tabs = ["HOME"]

  const navRoutes = [
    { name: 'Home', link: '/', value: 'LandingPage', icon: <Home/> },
    { name: 'Target Downloads', link: '/target-downloads', value: 'TargetDownloads' , icon: <Public/>},
  ];

  const profileRoutes = [
    { name: 'My Profile', link: '/member/my-profile', value: 'my-profile', icon: <Person/> },
    { name: 'Sales Commission', link: '/member/sales-commission', value: 'sales-commission', icon: <MonetizationOn/> },
    { name: 'Activities', link: '/member/activities', value: 'activities', icon: <Assessment/> },
    { name: 'Reports Portal', link: '/member/reports-download', value: 'reports-download', icon: <CloudDownload/> },
    { name: 'Endorsements', link: '/member/endorsements', value: 'endorsements', icon: <Book/> },
    { name: 'License Portal', link: '/member/license-reminders', value: 'license-reminders', icon: <Notifications/> },
    { name: 'Documents', link: '/member/documents', value: 'documents', icon: <Assignment/> },
    { name: 'Learning Portal', link: '/member/courses', value: 'courses', icon: <ChromeReaderMode/> },
    { name: 'Dedicated Status', link: '/member/dedicated-status', value: 'dedicated-status', icon: <Stars/> },
    { name: 'Subscriptions', link: '/member/subscriptions', value: 'subscriptions', icon: <Subscriptions/> },
    // { name: 'NewsLetter', link: '/member/newsLetter', value: 'newsLetter', icon: <BookmarkRounded/> },
    { name: 'Emails', link: '/member/member-emails', value: 'member-emails', icon: <Email/> },

  ];

  //Currently showing
  const nonSalesRepsProfileRoutes = [
    { name: 'My Profile', link: '/member/my-profile', value: 'my-profile', icon: <Person/> },
    { name: 'Activities', link: '/member/activities', value: 'activities', icon: <Assessment/> },
    { name: 'Reports Portal', link: '/member/reports-download', value: 'reports-download', icon: <CloudDownload/> },
    { name: 'Endorsements', link: '/member/endorsements', value: 'endorsements', icon: <Book/> },
    { name: 'License Portal', link: '/member/license-reminders', value: 'license-reminders', icon: <Notifications/> },
    { name: 'Documents', link: '/member/documents', value: 'documents', icon: <Assignment/> },
    { name: 'Learning Portal', link: '/member/courses', value: 'courses', icon: <ChromeReaderMode/> },
    { name: 'Dedicated Status', link: '/member/dedicated-status', value: 'dedicated-status', icon: <Stars/> },
    { name: 'Subscriptions', link: '/member/subscriptions', value: 'subscriptions', icon: <Subscriptions/> },
    // { name: 'NewsLetter', link: '/member/newsLetter', value: 'newsLetter', icon: <BookmarkRounded/> },
    { name: 'Emails', link: '/member/member-emails', value: 'member-emails', icon: <Email/> },
  ];

  const expiredProfileRoutes = [
    { name: 'My Profile', link: '/member/my-profile', value: 'my-profile', icon: <Person/> },
  ]

  //Partner Imported Members 
  const partnerImportedMembersRoute = [
    { name: 'My Profile', link: '/member/my-profile', value: 'my-profile', icon: <Person/> },
    { name: 'License Portal', link: '/member/license-reminders', value: 'license-reminders', icon: <Notifications/> },
    { name: 'Reports Portal', link: '/member/reports-download', value: 'reports-download', icon: <CloudDownload/> },
  ];

  const partnerRoute = [
    { name: 'Partner Dashboard', link: '/member/partner-dashboard', value: 'partner-dashboard', icon: <DashboardIcon/> }
  ];

  const salesRepRoute = [
    { name: 'Sales Rep Dashboard', link: '/member/sales-rep-dashboard', value: 'sales-rep-dashboard', icon: <DashboardIcon/> }
  ];

  const adminRoutes = [
    { name: 'Admin Portal', link: '/admin/MainAdmin', value: 'admin-dashboard', icon: <SupervisorAccount/> }
  ];

  const logoutRoutes = [
    { name: 'Logout', link: '/logout', value: 'logout', icon: <PowerSettingsNew/> },
  ];

  const profileList = (
    <List
      component="div"
      aria-labelledby="member pages"
      className={classes.listParent}
    >
      {daysLeft <= 1
        ? 
        // Here we check if it's a GOSA member and not have paid yet and normal expired membership 
        (
          // props.memberData.status == 1 && props.memberData.store == "GOSA" ? 
          props.memberData.status == 9 ? 
          partnerImportedMembersRoute.map((route) => (
            <ListItem
              selected={sideIndex === route.value}
              button
              key={`${route}${route.value}`}
              component={Link}
              to={route.link}
              onClick={(event) => handleChangeSideIndex(event, route.value)}
              classes={{ selected: classes.listSelected }}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText>{route.name}</ListItemText>
            </ListItem>
          ))
          :
          expiredProfileRoutes.map((route) => (
            <ListItem
              selected={sideIndex === route.value}
              button
              key={`${route}${route.value}`}
              component={Link}
              to={route.link}
              onClick={(event) => handleChangeSideIndex(event, route.value)}
              classes={{ selected: classes.listSelected }}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText>{route.name}</ListItemText>
            </ListItem>
          ))
        )
        



        : !hasSalesReps || !_isSalesRep
        ? nonSalesRepsProfileRoutes.map((route) => (
            <ListItem
              selected={sideIndex === route.value}
              button
              key={`${route}${route.value}`}
              component={Link}
              to={route.link}
              onClick={(event) => handleChangeSideIndex(event, route.value)}
              classes={{ selected: classes.listSelected }}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText>{route.name}</ListItemText>
            </ListItem>
          ))
        : profileRoutes.map((route) => (
            <ListItem
              selected={sideIndex === route.value}
              button
              key={`${route}${route.value}`}
              component={Link}
              to={route.link}
              onClick={(event) => handleChangeSideIndex(event, route.value)}
              classes={{ selected: classes.listSelected }}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText>{route.name}</ListItemText>
            </ListItem>
          ))}
    </List>
  );

  // if((this?.props?.memberData?.status == 5)){
  //   this.props.history.push('/member/profile-completion');
  //   alert("redirect")
  //  }

  const partnerList = (
    <List
      component="nav"
      className={classes.listParent}
      aria-label="partner area options"
    >
      {partnerRoute.map(route => (
        <ListItem
          selected={sideIndex === route.value}
          button
          divider
          key={`${route}${route.value}`}
          component={Link}
		  to={route.link}
		  onClick={() => {
			props.onMemberStateChange(true, "isPartner");
			props.onToggleHamburgerChange(true);
		  }}
          classes={{ selected: classes.listSelected }}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText >{route.name}</ListItemText>
        </ListItem>
      ))}
    </List>
  );

  const salesRepList = (
    <List
      component="nav"
      className={classes.listParent}
      aria-label="sales rep area options"
    >
      {salesRepRoute.map(route => (
        <ListItem
          selected={sideIndex === route.value}
          button
          divider
          key={`${route}${route.value}`}
          component={Link}
		  to={route.link}
		  onClick={() => {
			props.onMemberStateChange(true, "isSalesRep");
			props.onToggleHamburgerChange(true);
		  }}
          classes={{ selected: classes.listSelected }}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText >{route.name}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
  
  const adminList = (
    <List
      component="nav"
      className={classes.listParent}
      aria-label="admin area options"
    >
      {adminRoutes.map(route => (
        <ListItem
          selected={sideIndex === route.value}
          button
          divider
          key={`${route}${route.value}`}
          component={Link}
        //  to={route.link}
        //  onClick={(event) => handleChangeSideIndex(event, route.value)}
		  to={route.link}
		  onClick={() => {
			props.onMemberStateChange(true, "isAdmin");
			props.onToggleHamburgerChange(true);
		  }}
          classes={{ selected: classes.listSelected }}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText >{route.name}</ListItemText>
        </ListItem>
      ))}
    </List>
  );

  const logoutList = (
    <List className={classes.listParent}>
      {logoutRoutes.map(route => (
        <ListItem
          selected={sideIndex === route.value}
          button
          key={`${route}${route.value}`}
          component={Link}
          to={route.link}
          onClick={(event) => handleChangeSideIndex(event, route.value)}
          classes={{ selected: classes.listSelected }}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText >{route.name}</ListItemText>
        </ListItem>
      ))}
    </List>
  );

  const appBarTabs = (
    <Grid item container alignItems="center" spacing={2} md={8}>
	  {matches ? null : 
        <Tabs
            className={classes.tabs}
            style={{fontFamily: 'Open Sans SemiBold'}}
            value={value}
            onChange={handleChange}
            variant="scrollable"
        >
        </Tabs>}
    </Grid>
  );

  const mobileDrawer = (
    <Drawer
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      className={classes.drawerOpen}
      classes={{ paper: classes.drawerPaper }}
      ModalProps={{ keepMounted: true }}
    >
      <Grid container justify="center">
        <img className={classes.sideBarLogo} src={partnerDetails ? partnerDetails.logo : 'default-logo-path.jpg'} alt="Partner logo"/>

        {!props.isAuth || (props.memberData.status == 5)  ? <></> :
          <List
            component="nav"
            aria-labelledby="mobile member list"
            className={classes.ListParent}
          >
            <ListItem button divider onClick={handleMemberToggle}>
              <ListItemIcon>
                <Person/>
              </ListItemIcon>
              <ListItemText primary="Member Profile"/>
              {memberListOpen ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={memberListOpen} unmountOnExit>
              {profileList}
            </Collapse>
          </List>
        }

        {props.isAuth && _adminLevel && !(props.memberData.status == 5)  ? adminList : <></>}
        
        {props.isAuth && _partnerLevel && !(props.memberData.status == 5)  ? partnerList : <></>}

        {props.isAuth && _salesRepLevel && !_partnerLevel && !(props.memberData.status == 5) ? salesRepList : <></>}
        

        {props.isAuth ? logoutList : <></>}
      </Grid>
    </Drawer>
  );

  const desktopDrawer = (
    <React.Fragment>
      {!props.isAuth ? <></> :
        <Drawer
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          anchor="left"
        >
          <div className={classes.sideBarListMargin} />
          <Grid container justifyContent="center" style={{marginTop:'2rem'}}>
            {!(props.memberData?.status == 5) && <List
              component="nav"
              aria-labelledby="desktop list"
              className={classes.ListParent}
            >
              <ListItem button divider onClick={handleMemberToggle}>
                <ListItemIcon>
                  <Person/>
                </ListItemIcon>
                <ListItemText primary="Member Profile"/>
                {memberListOpen ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse in={memberListOpen} unmountOnExit>
                {profileList}
              </Collapse>
            </List>}
          
            {props.isAuth && _adminLevel && !(props.memberData.status == 5) ? adminList : <></>}
          
            {props.isAuth && _partnerLevel && !(props.memberData.status == 5) ? partnerList : <></>}
            
            {props.isAuth && _salesRepLevel && !_partnerLevel  && !(props.memberData.status == 5) ? salesRepList : <></>}

            {logoutList}
          </Grid>
        </Drawer>
      }
    </React.Fragment>
    
  );


  useEffect(() => {

    const storeId = Number(window.localStorage.getItem("store"));

    if(props.memberData && loggedIn && storeId > 0 ){
      const storeId =  window.localStorage.getItem("store");

      axios({url: `${API_URL}/Stores`,method: 'get',}).then(res => {
        const matchingStore = res.data.find(store => store.storeId == storeId);
          
        if (matchingStore) {
          setPartnerDetails(matchingStore);
        } else {
          console.log("No matching store found");
        }
      })
      .catch(err => {
        console.error("Error fetching stores:", err);
      });
    }    
    else if(loggedIn && !storeId){

      axios({url: `${API_URL}/Stores`,method: 'get',}).then(res => {
        const matchingStore = res.data.find(store => store.storeId == props.memberData.storeId);
          
        if (matchingStore) {
          window.localStorage.setItem("store", props.memberData.storeId);
          setPartnerDetails(matchingStore);
        } else {
          console.log("No matching store found");
        }
      })
      .catch(err => {
        console.error("Error fetching stores:", err);
      });
    }
    
  }, []);
  
  useEffect(() => {
    [...navRoutes].forEach(route => {
      switch (window.location.pathname) {
      case `${route.link}`:
        if (navIndex !== route.value) {
          setNavIndex(route.value);
        }
        break;
      default:
        break;
      }
    });

    const loadPartnerNavbar =  window.localStorage.getItem("partnerContent");
    const storeId =  window.localStorage.getItem("store");

    if(loadPartnerNavbar === 'true')
    {
      if(props.memberData && loggedIn && storeId){

        axios({url: `${API_URL}/Stores`,method: 'get',}).then(res => {
          const matchingStore = res.data.find(store => store.storeId == storeId);
            
          if (matchingStore) {
            window.localStorage.removeItem("partnerContent");
            setPartnerDetails(matchingStore);
            console.log("Matching Store:", matchingStore);
          } else {
            console.log("No matching store found");
          }
        })
        .catch(err => {
          console.error("Error fetching stores:", err);
        });
      }
    }
    else if(loggedIn && !storeId){

      console.log("Is logged in but no storeId")
    }

    
  }, [navIndex, navRoutes]);

  useEffect(() => {
    [...profileRoutes].forEach(route => {
      switch (window.location.pathname) {
      case `${route.link}`:
        if (sideIndex !== route.value) {
          setSideIndex(route.value);
        }
        break;
      default:
        
        break;
      }
    });

    const memNo =  window.localStorage.getItem("memNo");
    const storeId =  window.localStorage.getItem("store");

    if(props.memberData.memNo && memNo)
    {
       //if the memNo's are not the same 
        if(props.memberData.memNo !== memNo)
        {
          // Get Member data 
          axios({url: `${API_URL}/Stores`,method: 'get',}).then(res => {
            const matchingStore = res.data.find(store => store.storeId == storeId);
              
            if (matchingStore) {
              window.localStorage.removeItem("partnerContent");
              setPartnerDetails(matchingStore);
            } else {
              console.log("No matching store found");
            }
          })
          .catch(err => {
            console.error("Error fetching stores:", err);
          });
        }
    }
    
    const loadPartnerNavbar =  window.localStorage.getItem("partnerContent");
    if(loadPartnerNavbar === 'true')
    {
        axios({url: `${API_URL}/Stores`,method: 'get',}).then(res => {
          const matchingStore = res.data.find(store => store.storeId == storeId);
            
          if (matchingStore) {
            window.localStorage.removeItem("partnerContent");
            setPartnerDetails(matchingStore);
          } else {
            console.log("No matching store found");
          }
        })
        .catch(err => {
          console.error("Error fetching stores:", err);
        });
    }
    else if(!props.isAuth){
      setPartnerDetails(null);
    }

  }, [sideIndex, profileRoutes]);
  
  useEffect(() => {
    [...nonSalesRepsProfileRoutes].forEach(route => {
      switch (window.location.pathname) {
      case `${route.link}`:
        if (sideIndex !== route.value) {
          setSideIndex(route.value);
        }
        break;
      default:
        break;
      }
    });
  }, [sideIndex, nonSalesRepsProfileRoutes]);

const sendVerificationCode =(email,resend)=>{
    emailVerificationService.sendVerificationCode(email).then(res => {
      if(resend)
      openSnackbar();
    });

    if(!dialogOpen)
      setDialogOpen(true)
}


const onCodeChange =(e)=> {
    e.preventDefault();
    setCode(e.target.value );
  }

  // For snack bar 
  const openSnackbar = () => {
    setOpen(true);
    
  };

  const openSnackbarVerify = () => {
    setDialogOpen(false);
    setOpenVerify(true);
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpenVerify(false);
    setOpenVerifyError(false);
  };

  const verifyEmail = (event, reason) => {
    // Send API Request 
    emailVerificationService.verifyMemberEmail(props.memberData.email, code).then(response =>{
      window.localStorage.setItem("verified", true);
      window.location.reload();
    })
    .catch((error) =>{
      setOpenVerifyError(true);
      console.log("Verification Failed", error);
    });

  };

  return (
    <div className={classes.root}>
      <AppBar
        className={`${classes.appBar} ${classes.backdrop}`}
        color="default"
      >
        <Toolbar disableGutters>
          <Grid container className="mr-6">

            {
              partnerDetails &&
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={2}
              sm={2}
              md={1}
            >
              <Button
              justifyContent="flex-start"
                className={classes.logoContainer}
                component={Link}
                to="/"
                onClick={() => setNavIndex("home")}
                disableRipple
              >
                <img
                  style ={{maxHeight:'60px',marginTop:'-10px'}}
                  className={classes.navBarLogo}
                  src={partnerDetails ? partnerDetails.logo : 'default-logo-path.jpg'}
                  alt="Partner logo"
                />
              </Button>

              <Button
                size="small"
                style={breakpointMatch ? { marginRight:"-110px"}: { marginRight:"-45px"}}
                component={Link}
                onClick={() => {
                  window.open(partnerDetails.website, 'noopener,noreferrer');
                }}
              
                variant="outlined"
                color="primary"
                >
                Visit Site
                </Button>
            </Grid>
            }

            {breakpointMatch ? appBarTabs : <></>}

            <AuthAvatar
              isAuth={props.isAuth}
              memberData={props.memberData}
              profilePicture={props.profilePicture}
              isMemberExpired={props.isMemberExpired}
            />



            <Hidden mdUp>
              <Grid item container justify="center" xs={1}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  style={{ outline: "none" }}
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
        {props.isAuth && !props.memberData.verified && (
          <Alert size="small" variant="filled" severity="warning">
            <AlertTitle>
              To unlock all features please verify your email{" "}
              <strong>
                {" "}
                {props.memberData.email}
                <Button
                  onClick={(_) => sendVerificationCode(props.memberData.email, false)}
                  variant="outlined"
                  style={{ marginLeft: "3rem" }}
                  color="inherit"
                  size="small"
                >
                  Verify
                </Button>
              </strong>
            </AlertTitle>
          </Alert>
        )}

        <Dialog
          open={dialogOpen}
          maxWidth={"xs"}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="right">
            <IconButton
              aria-label="close"
              align="right"
              className={classes.closeButton}
              onClick={(_) => setDialogOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <strong align="center" style={{ marginTop: "-50px" }}>
            {" "}
            Verify email
          </strong>
          <DialogContent>
            <DialogContentText>
              <Alert severity="success" style={{ fontSize: "small" }}>
                <AlertTitle>
                  We've sent verification code to your email <br />
                  {props.memberData.email}
                </AlertTitle>
              </Alert>
            </DialogContentText>

            <form className={classes.form} noValidate>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Code"
                type="text"
                fullWidth
                onChange={(e) => onCodeChange(e)}
              />
            </form>

            <Button
              size="small"
              fullWidth
              onClick={() => sendVerificationCode(props.memberData.email, true)}
              variant="outlined"
              color="primary"
            >
              Resend code
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
              <AlertTitle>Verification code for email {props.memberData.email} has been
                sent successfully</AlertTitle>
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  style={{ color: "white" }}
                  disabled={code != null ? null : true}
                  onClick={() => verifyEmail()}
                  variant="contained"
                  color="primary"
                >
                  Verify
                </Button>
              </Grid>

            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar open={openVerify} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
              <AlertTitle>Congratulations your account is verified</AlertTitle>
              </Alert>
            </Snackbar>
        
      </AppBar>
        <Snackbar open={openVerifyError} autoHideDuration={7000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
          <AlertTitle>Verification failed. Invalid code.</AlertTitle>
          </Alert>
        </Snackbar>
      <div className={classes.toolbarMargin} />
      <nav className={classes.drawer} aria-label="sidebar navigation">
        {breakpointMatch ? desktopDrawer : mobileDrawer}
      </nav>
    </div>
  );
}

const mapStateToProps = (state) => ({
	adminLevel: state.loginReducer.userProfileDetails.adminLevel,
	partnerLevel: state.loginReducer.userProfileDetails.partnerLevel,
	salesRepLevel: state.loginReducer.userProfileDetails.salesRepLevel,
	salesReps: state.nCommissionReducer.salesReps,
	memNo: state.AuthReducer.memNo,
	token: state.AuthReducer.token
});

const mapDispatchToProps = dispatch => {
	return {
	  onMemberStateChange: (value, vname) =>
		dispatch({
		  type: actionTypes.UPDATE_MEMBER_STATE,
		  varName: vname,
		  varValue: value
		}),
	  onToggleHamburgerChange: toggle =>
       dispatch({
         type: actionTypes.TOGGLE_HAMBURGER,
         toggleHamburger: toggle
       }),
	  onLoginPageChange: loginToRessetPassword =>
	   dispatch({ 
		   type: actionTypes.UPDATE_LOGIN_PAGE_TOGGLER, 
		   loginToRessetPassword: loginToRessetPassword 
	   }),
   	  onLandingPageContentToggle: currTab =>
	   dispatch({ 
		   type: actionTypes.UPDATE_LANDING_PAGE_CONTENT, 
		   landingPageContentToggle: currTab 
	   }),
	  onFirearmOwnersToggle: currTab =>
	   dispatch({ 
		   type: actionTypes.UPDATE_FIREARM_OWNERS_CONTENT, 
		   fireararmOwwners: currTab 
	   }),
	  onHuntingToggle: currTab =>
	   dispatch({ 
		   type: actionTypes.UPDATE_HUNTING_CONTENT, 
		   huntingTab: currTab 
	   }),
	  onSportShootingToggle: currTab =>
	   dispatch({ 
		   type: actionTypes.UPDATE_SPORT_SHOOTING_CONTENT, 
		   sportShooting: currTab 
	   })
	 };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(NavBar);