import React, { Component } from 'react';
import { Redirect,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";
import { FRONT } from '../../../../API/APIendpoint';
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core/';
import { LockOutlined } from '@material-ui/icons';

import * as actions from '../../../store/Auth/Login/actions';
import { 
  updateObject, 
  checkValidity
} from '../../../shared/utility';
import { MappedInput } from '../../../components';
import { 
  emailUsernameConfig,
  passwordConfig
} from '../../../components/Auth/Login/controls';
import JWT from 'jwt-decode';
import MemberService from '../../../services/member.service';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(4, 0, 4),
  },
  linkFont: {
    fontSize: '0.8rem',
    marginTop:'1.0em'
  },
  errorPadding: {
    padding: theme.spacing(2)
  }
});

class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      showPassword: false,
      controls: this.initControls(),
      isActive:false

    };
	  this.memberService = MemberService(this.props.token);
    this.showHide = this.showHide.bind(this);
  }
  
  initControls = () => ({
    email: {...emailUsernameConfig},
    password: {...passwordConfig}
  });

  showHide(e){
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  inputChangeHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.controls[controlName].config.validate,
      this.state.controls[controlName].config.elementConfig.type
    );
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.controls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });
    this.setState({controls:updatedControls});
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value);
    // localStorage.setItem("sendLoginEmail", true);
  }
 activateButton = () => {
    this.setState({
        ...this.state,
        isActive:true
    });
}

deactivateButton = () => {
  this.setState({
    ...this.state,
    isActive:false
});
}

  render() {
    const {classes} = this.props;
    const formElementsArray = [];

    let authRedirect = null;
    let errorMessage = null;

    for (let key in this.state.controls) {
      formElementsArray.push( {
        id: key,
        config: this.state.controls[key].config,
        value: this.state.controls[key].value
      });
    }

    let form = formElementsArray.map( formElement => (
      <FormControl
        key={formElement.id}
        variant="outlined"
        margin="normal"
        fullWidth
      >
        <MappedInput
          elementConfig={formElement.config.elementConfig}
          elementType={formElement.config.elementType}
          value={formElement.value}
          invalid={!formElement.config.valid}
          shouldValidate='true'
          touched={formElement.config.touched}
          changed={(event) => this.inputChangeHandler(event, formElement.id)} 
          showHide={formElement.id !== 'password' ? null : this.showHide}
          showPassword={formElement.id !== 'password' ? null : this.state.showPassword}
        />
        <FormHelperText
          error={!formElement.config.value}
        >
          {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
        </FormHelperText>
      </FormControl>
    ));

    if (this.props.error) {
      errorMessage = (
        <Typography
          component="h1"
          variant="h6"
          color="error"
          className={classes.errorPadding}
        >
          {this.props.error.data.message}
        </Typography>
      );
    }

    if (this.props.isAuthenticated ) {
      const decode = JWT(this.props.token);
      this.memberService = MemberService(this.props.token);

      //Set Patner Navbar to updated 
       window.localStorage.setItem("partnerContent", true);

      // const emailData = {
      //   Firstname: decode.given_name,
      //   Surname: decode.family_name,
      //   toEmail: decode.email
      // }
      // const sendLoginEmail = localStorage.getItem("sendLoginEmail");
      
      //send email to the user 
      // if(sendLoginEmail)
      // {
      //   this.memberService.sendLoginEmail(emailData)
      //   localStorage.setItem("sendLoginEmail", false); //Reset email sending
      // }

      authRedirect = (this?.props?.member?.status == 5  || this?.props?.member?.status == 8) ? <Redirect to={'/member/profile-completion'} /> : <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <Grid container alignItems="center" justify="center">
        <Grid item  xs={9} sm={6} md={5} lg={4} xl={3}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlined />
            </Avatar>
            
            <Typography variant="h4">
              Sign in
            </Typography>

            {authRedirect}
            {errorMessage}

            <form onSubmit={this.submitHandler} className={classes.form}>
              {form}
              <div className="placeAtCenter">
              <ReCAPTCHA
                        sitekey={FRONT === "http://localhost:3000/" ? 
                            this.props.recatchaKey.localHostKey : FRONT === "http://qaportal.narfo.co.za/" ?
                                this.props.recatchaKey.narfoQAKey : this.props.recatchaKey.narfoProductionKey}
                        onChange={this.activateButton}
                        onExpired={this.deactivateButton}
                    />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled = {!this.state.isActive}
              >
                Sign In
              </Button>
              

            </form>
            <Grid container>
              <Grid item xs>
                <Button component={Link} to="/Login/ResetPassword" className={classes.linkFont} variant="contained" color="primary">
                    Forgot password?
                </Button>
              </Grid>

              <Grid item >
              <Button component={Link} to="/sign-up" className={classes.linkFont} variant="contained" color="primary">
                  Don&apos;t have an account? Sign Up
              </Button>
              </Grid>
            </Grid>

          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = state => ({
	loading: state.AuthReducer.loading,
	error: state.AuthReducer.error,
	isAuthenticated: state.AuthReducer.token !== null,
	authRedirectPath: state.AuthReducer.authRedirectPath,
	token: state.AuthReducer.token,
  recatchaKey: state.RecaptchaKeyReducer,
  member: state.ProfileReducer.memberData
});

const mapDispatchToProps = dispatch => ({
  onAuth: (email, password) => dispatch(actions.auth(email, password))
});

export default connect(mapStatetoProps, mapDispatchToProps)(withStyles(styles)(Login));